.overview-page{
    .actions-section{
        width: 99%;
        margin:0px auto;
        padding: 15px;
        background-color: #fff;
        border: 1px solid #eee;
    }

    .actions-grid{
        width: 85%;
        display: grid;
        grid-template-columns: 20% 20% 20% 15% 10%;
        // justify-content: space-between;
        grid-column-gap: 2%;
        text-align: center;
    }

    .page-content-with-tabs {
        min-height: 50vh;
        margin-top: 10px;
        // padding: calc(#{$header-height} + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) $footer-height calc(#{$grid-gutter-width} / 2);
    }

    .custom-tab-button>button {
        padding: 1rem 1rem !important;
        font-size: 16px !important;
    }
    .custom-tab-button>button::after{
        border-bottom: 3px solid #000 !important;
    }
    
    .search-button{
        width: 100%;
        padding: 0; 
        background-color: #fff;
        color: #696969;
        font-size: 13px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: text;
    }
    
    .search-button > i{
        margin-right: 3px;
    }

    // .t-a-l{
    //     text-align: left;
    // }

    .card-container{
        display: grid;
        grid-template-columns: 18% 18% 18% 18% 18%;
        justify-content: space-between;
        padding: 2% 8px;
    }

    .build-card{
        width: 100%;
        text-align: center;
        border-radius: 18px;
        background-color: #fff;
        border: 1px solid #eee;
        // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        // box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    }

    .card-head-text{
        padding: 5px 0;
        background: #afafaf;
        border-radius: 18px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        font-weight: 700;
    }

    .dark.card-head-text{
        padding: 5px 0;
        background:#232121;
        color: #fff;
        border-radius: 18px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        font-weight: 700;
    }

    .build-card > img{
        width: 45%;
        background-color: #bbb;
        border-radius: 50%;
        padding: 20px;
        margin-bottom: 1rem;
    }

    .product-name{
        font-weight: 700;
        margin-bottom: 0;
        color: #232121;
    }

    .m-b-10{
        margin-bottom: 10px;
    }

    .date-text{
        margin-bottom: 1rem;
        font-weight: 700;
        color: #232121;
    }

    .f-w-6{
        font-weight: 500;
    }

    .active-menu {
        width: 90%;
        margin: 10% auto;
        padding: 10px 18px;
        font-size: 16px;
        display: block;
        color: #e09d08;
        border: 1px solid #e09d08;
        cursor: pointer;
    }
    .menu-btn {
        width: 90%;
        padding: 10px 18px;
        margin: 10% auto;
        display: block;
        font-size: 16px;
        border: 1px solid #000;
        // border-radius: 15px;
        cursor: pointer;
    }

    .menu-btn-disabled {
        width: 90%;
        padding: 10px 18px;
        margin: 10% auto;
        display: block;
        font-size: 16px;
        border: 1px solid #aaaaaa;
        // border-radius: 15px;
        color: #aaaaaa;
        cursor: pointer;
    }

    
    .image-details{
        width : 45%;
    }

    .table-row-section {
        margin: 0 0 1% 0;
        padding: 0 10px;
        background-color: #fff;
        border-radius: 8px;
        // box-shadow: 0 2px 3px 1px #cccccc;
    }

    .custom-table-row{
        background-color: #eee;
    }

    .custom-table-head,
    .custom-table-body {
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        align-items: center;
    }

    .custom-table-head {
        padding: 10px;
    }

    .table-section {
        width: 99%;
        margin: 1% auto;
    }

    .modal-custom-table-head,
    .modal-custom-table-body {
        display: grid;
        grid-template-columns: 20% 20% 15% 15% 15% 15%;
        align-items: center;
    }

    .secondary-scroll-section{
        width : 100%;
        height : 250px;
        overflow-x: hidden;
        overflow-y: auto;
        // background-color: #000;
    }
    
    .icon-data{
        width: 20px;
    }

    .listing-table-variable{
        cursor: pointer;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10+ and Edge */
        user-select: none; /* Standard syntax */ 
    }

}