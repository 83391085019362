//Modal styles
.modal-backdrop.show {
    opacity: 0.8 !important;
}

.modal-head>span {
    line-height: 2;
}

.custom-body-style {
    margin: 6%;
    border: 1px solid #b5b5b5;
    padding: 2.3rem 1rem 1rem 1rem !important;
    // background-color: #ccc;
    border-radius: 10px;
}

.modal-body-title {
    margin-bottom: 0;
    padding: 1px 4px;
    position: absolute;
    left: 50px;
    top: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #000;
    background-color: #fff;
    z-index: 1;
}

.bordered-modal-body {
    margin: 3% 4% 1% 4%;
    border: 2px solid #ccc;
    border-radius: 5px;
}

.form-inner-section:first-of-type {
    border-right: 1px;
    border-width: medium;
    border-style: solid;
    border-color: #fff;
    border-bottom: 0px;
    border-left: 0px;
    border-top: 0px;
}

.form-option {
    width: 70%;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
}

.modal-foot {
    margin: 2%;
}

.modal-btn {
    margin-right: 2%;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 15px;
    border: 2px solid #ccc;
}

.modal-table-head {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 5% 20% 15% 20% 20% 20%;
    text-align: center;
}

.foot-notes {
    margin: 0% 0 2% 0;
    display: grid;
    grid-template-columns: 8% 80%;
    align-items: center;
}

.foot-notes>i {
    font-size: 40px;
}

.footer-btns {
    margin: 4% 0 0 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.footer-btns>button {
    width: 20%;
    margin-left: 5%;
}

.footer-btns>span {
    cursor: pointer;
}

.modal-interior-grid {
    display: grid;
    grid-template-columns: 25% 70%;
}

