// 
// authentication.scss
//
// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

.no-mar {
    margin: 0;
}

.p-tl {
    padding: 3% 0 2% 8%;
    ;
}

.p-b {
    padding-bottom: 2%;
    margin-bottom: 3%;
}

.logo-container {
    margin: 5% 0 0 5%;
}