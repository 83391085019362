.manageAccess-page{
    
    .actions-section {
        width: 99%;
        margin: 0px auto;
        padding: 15px;
        background-color: #fff;
        border: 1px solid #eee;
    }

    .actions-grid {
        width: 85%;
        display: grid;
        grid-template-columns: 20% 20% 20% 10% 10%;    
        column-gap: 2%;
        // text-align: center;
    }

    .table-section {
        width: 99%;
        margin: 1% auto;
    }
    .table-row-section {
        margin: 0 0 1% 0;
        padding: 0 10px;
        background-color: #fff;
        border-radius: 8px;
        // box-shadow: 0 2px 3px 1px #cccccc;
    }
    .table-row-section:hover {
        box-shadow: 0 2px 3px 1px #cccccc;
    }

    .custom-table-head,
    .custom-table-body {
        display: grid;
        grid-template-columns: 15% 20% 20% 30% 10%;
        // justify-content: space-between;
        align-items: center;
    }
    .custom-table-head {
        padding: 10px;
    }

    .collapse-table-section {
        display: grid;
        grid-template-columns: 25% 40% 20% 10%;
        justify-content: left;
        padding: 15px 0;
    }

    .table-content-padding{
        padding: 20px 0px;
    }

    .detail-section {
        background-color: #fff;
        padding: 15px 20px;
        height: max-content;
    }

    .equal-grid>div>p>span {
        font-weight: 700;
    }
    .equal-grid {
        display: grid;
        grid-template-columns: 45% 30%;
        column-gap: 2%;
        margin-left: 20px;
        // justify-content: space-between;
    }

    .grid-box {
        display: grid;
        padding: 15px;
        grid-template-columns: 70% 28%;
        justify-content: space-between;
    }

    .back-btn{
        width: 30px;
        margin-bottom: 25px;
    }

    // ==== CSS related to logs ====
    .second-child{
        background-color: #fff;
        padding : 15px
    }

    .head-section{
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .title-logo-div{
        // display: grid;
        // grid-template-columns: 10% 89%;
        align-items: center;
    }

    .setting-icons-div{
        justify-self: end;
        align-self: center;
    }
    .setting-icons-div > i{
        margin-right: 12px;
        font-size: 16px;
    }
    .setting-icons-div > div{
        margin-right: 12px;
        font-size: 16px;
    }
    .setting-icons-div-first{
        margin-right: 12px;
        font-size: 16px;
    }
    .task-timeline-container{
        height: 980px; // was 86% 
        overflow-y: auto;
        // padding: ;
    }

    // ==== Custom modal content ====
    .delete-modal{
        text-align: center;
        font-size: 25px;
    }

    .modal-padding{
        padding: 10px 20px;
    }

    .edit-modal-grid{
        display: grid;
        grid-template-columns: 20% 77%;
        column-gap: 3%;
    }

    .details-permissions-listing{
        margin-left: 20px;
        font-size: 18px;
        margin-top: 30px;
    }

    
}