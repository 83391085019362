.customer-page {
    .search-section {
        width: 99%;
        margin: 0 auto;
        padding: 15px 10px;
        background-color: #fff;
    }
    .search-section>p {
        margin: 8px 0 0 0;
    }
    .input-grid-section {
        display: grid;
        grid-template-columns: 12% 25% 15% 10%;
        grid-column-gap: 10px;
        margin-top: 6px;
        // justify-content: space-between;
    }
    .input-grid-section>select,
    .input-grid-section>input,
    .input-grid-section>button {
        // height: 30px;
        padding: 5px 5px;
    }
    .input-grid-section>input {
        border: 1px solid #ced4da;
        border-radius: 5px;
        outline: none;
    }
    .devices-section {
        display: grid;
        width: 99%;
        margin: 10px auto;
        grid-template-columns: 33% 33% 33%;
        background-color: #fff;
        justify-content: space-between;
    }
    .rw-widget-picker {
        height: 100%;
    }
    .image-holder>img {
        object-fit: contain;
    }
    .grid-box {
        display: grid;
        padding: 15px;
        grid-template-columns: 74% 24%;
        justify-content: space-between;
    }
    .head-section {
        display: grid;
        grid-template-columns: 75% 23%;
        padding: 3%;
        background-color: #fff;
    }
    .details-section {
        // background-color: #fff;
    }
    .detail-section-back {
        background-color: #fff;
    }
    .detail-section-back>img{
        margin-left: 10px;
        width: 56px;
        height: 56px;
        padding: 12px;
    }
    .head-detail-section {
        display: grid;
        grid-template-columns: 20% 79%;
        justify-content: space-between;
        background-color: #fff;
    }
    .head-detail-section>img {
        width: 100%;
        // padding: 1%;
        justify-self: center;
        align-self: center;
    }
    .head-detail-section>div {
        padding: 15px 0 10px 0;
    }
    .head-detail-section>div>p {
        margin-bottom: 5px;
    }
    .equal-grid>div>p>span {
        font-weight: 700;
    }
    .equal-grid {
        display: grid;
        grid-template-columns: 49% 48%;
        justify-content: space-between;
    }
    .details-section-body {
        margin-top: 1%;
        display: grid;
        grid-template-columns: 25% 74%;
        justify-content: space-between;
    }
    .details-section-body {
        background-color: #fff;
    }
    .active-menu {
        width: 90%;
        margin: 10% auto;
        padding: 10px 18px;
        font-size: 16px;
        display: block;
        color: #e09d08;
        border: 1px solid #e09d08;
        cursor: pointer;
    }
    .menu-btn {
        width: 90%;
        padding: 10px 18px;
        margin: 10% auto;
        display: block;
        font-size: 16px;
        border: 1px solid #000;
        // border-radius: 15px;
        cursor: pointer;
    }
    .menu-btn:hover {
        width: 90%;
        margin: 10% auto;
        display: block;
        padding: 10px 18px;
        cursor: pointer;
        transition: all .2s;
    }
    .display-screen {
        display: grid;
        grid-template-columns: 80%;
    }
    .display-screen>div {
        padding: 20px 0 0 10px;
    }
    .display-screen>div>p {
        margin-bottom: 8px;
    }
    .display-screen>div>p>span {
        font-weight: 700;
    }
    .config-screen {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .config-screen>div {
        padding: 10px 0 10px 10px;
    }
    .config-screen>div:last-child {
        padding: 13% 0 0 8%;
    }
    .equal-boxes {
        margin: 5% 0;
        display: grid;
        grid-template-columns: 31% 30%;
    }
    .grey-border {
        border-left: 4px solid #f5f6f9;
    }
    .scrollable-popover-content {
        font-size: 14px;
        height: 380px;
        overflow: auto;
    }
    .custom-tab-button>button {
        padding: 1rem 1rem !important;
        font-size: 16px !important;
    }
    .custom-tab-button>button::after{
        border-bottom: 3px solid #000 !important;
    }
    .action-validation-text {
        color: #ff0000;
        font-size: 11px;
    }

    .modal-internal-body{
        width : 100%;
        height: 250px;
        background-color: #fff;
    }
    .main-modal-body{
        padding: 15px 25px !important;
    }

    .otp-based-grid {
        display: grid;
        grid-template-columns: 35% 35%;
        column-gap: 5%;
    }
    .resend-otp-section{
        cursor: pointer;
        align-self: center;
    }

    @media (max-width: 1280px) {
        .input-grid-section {
            grid-template-columns: 15% 15% 20% 10%;
        }
    }
    @media (max-width: 768px) {
        .devices-section {
            grid-template-columns: 50% 50%;
        }
    }
    @media (max-width: 441px) {
        .devices-section {
            grid-template-columns: 100%;
        }
        .input-grid-section {
            grid-template-columns: 48% 48%;
            grid-row-gap: 10px;
        }
    }
}