
.float-container { text-align: left; border: solid 1px #ced4da; border-radius: 7.7px; padding: 0px 8px; position: relative; height: calc(1.5em + 0.94rem + 2px); padding: 0.47rem 0.75rem 0.47rem 0.75rem; line-height: 1; }
.float-container.wrapper-only { border-width: 0px!important; border-color: transparent; padding: 0px; }
.float-container.select select { background-color: transparent; border: 0px; height: 46px; width: 85%; outline: none; font-size: 18px; -webkit-appearance: none; appearance: none; -moz-appearance: none; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; margin: 0; }
.float-container.select select option { font-size: 15px; }
.float-container input { border: none; outline: 0; font-size: 15px; background-color: transparent; width: 100%;  }
.float-container textarea { border: none; outline: 0; font-size: 15px; background-color: transparent; width: 100%; height: auto; }
.float-container label { padding: 0 3px;color: $golden; font-weight: normal; transform-origin: top left; transition: all 300ms ease-in-out; font-size: 18px; position: absolute; transform: translate(0, 10px) scale(1); z-index: -1; }
.float-container.active label { transform: translate(0, -14px) scale(.65); background-color: #FFF; z-index: 1; }

.float-container .input-link { display: block; line-height: 48px; font-size: 16px; }

.float-container .input-buttons { position: absolute; bottom: 0px; right: 0px; padding: 10px; height: 48px; }
.float-container .input-buttons h5 { font-size: 20px; color: #999; margin: 0px; line-height: 30px; }
.float-container .input-buttons a { line-height: 30px; outline: none!important; color: #7BB0F1; font-weight: 600; letter-spacing: 0.34px; }
	
.float-container.error { border-color: #CF4343; }
.float-container.error label { color: #CF4343; }

.float-container.valid { border-color: #9FC576; }
.float-container.valid label { color: #999; }

.float-container.wrapper-only label { left: 15px; }
.float-container.wrapper-only .rw-widget-picker { height: 48px; border-radius: 7px; }


// File Upload Component

.removeSelected{
    margin-left: 2%;
    position: relative;
    top: 1px;
    color: #b1b1b1;
    transform: rotate(45deg);
    z-index: 99;
}