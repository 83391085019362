.history-page{
    .actions-section{
        width: 99%;
        margin:0px auto;
        padding: 15px;
        background-color: #fff;
        border: 1px solid #eee;
    }

    .actions-grid{
        width: 85%;
        display: grid;
        grid-template-columns: 20% 20% 20% 15%;
        // justify-content: space-between;
        grid-column-gap: 2%;
        text-align: center;
    }
    
    .search-button{
        width: 100%;
        padding: 0; 
        background-color: #fff;
        color: #696969;
        font-size: 13px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: text;
    }
    
    .search-button > i{
        margin-right: 3px;
    }
}