.page-content {
    // min-height: 100vh;
    padding-top: 70px;
}

.mar-r {
    margin-right: 35px;
    margin-bottom: 15px;
}

.ml-15 {
    margin-left: 15px;
}

.ln-report {
    margin: auto;
    padding: 0 20px 0 0;
    .danger-red {
        background-color: #ff0000 !important;
    }
    .average-orange {
        background-color: #f54a3d !important;
    }
    .free-green {
        background-color: #8bc34a !important;
    }
    .title-section {
        color: #000;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .title {
            font-size: 1rem;
            font-weight: 600;
            margin: 0.7rem 0 0 1rem;
        }
        .search {
            background-color: #fff;
            padding: 0.5rem 1rem;
            margin: 0.6rem 1rem 0 0;
            border-radius: 8px;
            cursor: pointer;
            img {
                height: 1rem;
                width: 1rem;
                margin-right: 8px;
            }
        }
    }
    .advancedSearch {
        // margin-left: 1rem;
        .search-bar {
            width: 80%;
            border: 2px solid #aaa;
            border-radius: 4px;
            outline: none;
            padding: 0 0 3px 4px;
            box-sizing: border-box;
            background-color: #fff;
            img {
                width: 1.4rem;
                margin-left: 0.3rem;
            }
            input {
                border: none;
                padding: 6px;
                width: 88%;
                outline: none;
                font-size: 1rem;
            }
            .icos {
                display: flex;
                justify-content: flex-end;
                float: right;
                margin-top: 4px;
                margin-right: 2px;
            }
            .last-ico {
                background-color: #aaa;
                padding: 6px;
                border-radius: 5px;
                width: 1.8rem;
                cursor: pointer;
            }
            .last-icon {
                background-color: #aaa;
                padding: 4px 6px;
                border-radius: 5px;
                width: 1.7rem;
                cursor: pointer;
            }
        }
    }
    .select-wrapper-1 {
        width: 55%;
    }
    .actions-section {
        width: 99%;
        margin: 0px auto;
        padding: 15px;
        background-color: #fff;
        border: 1px solid #eee;
    }
    .actions-grid {
        width: 85%;
        display: grid;
        grid-template-columns: 15% 30% 5% 10%;
        grid-column-gap: 2%;
        align-items: center;
        text-align: center;
    }
    .action-box-wrapper {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        margin: 0 0 20px 15px;
        label {
            font-size: 15px;
            font-weight: 700;
            color: #000;
            margin-bottom: 0px !important;
            margin-right: 5px;
        }
        .light {
            color: #999;
        }
        input[type=date] {
            padding: 5px;
            border: #ccc 1px solid;
            border-radius: 0.25rem;
        }
        .inp-field {
            flex: 2;
        }
        .blur {
            filter: blur(1px);
        }
    }
    @media (max-width: 1580px) {
        .select-wrapper-1 {
            width: 75%;
        }
        .actions-grid {
            grid-template-columns: 25% 35% 10% 10%;
        }
    }
    @media (max-width: 1280px) {
        .select-wrapper-1 {
            width: 75%;
        }
        .actions-grid {
            grid-template-columns: 25% 35% 10% 10%;
        }
    }
    @media (max-width: 1180px) {
        .select-wrapper-1 {
            width: 75%;
        }
        .actions-grid {
            grid-template-columns: 30% 40% 8% 10%;
        }
    }
    .inline-label {
        padding: 0 10px;
        margin-bottom: 0px !important;
    }
    .back-btn {
        font-size: 1rem;
        font-weight: 600;
        margin: 1rem 0;
        display: flex;
        cursor: pointer;
        img {
            height: 20px;
            margin-left: 10px;
            margin-right: 10px;
        }
        span {
            position: relative;
            top: 1px;
            color: #000;
            // padding-top: 5px;
        }
    }
    .search-body {
        display: flex;
        flex-direction: row;
        width: 70%;
        justify-content: space-between;
        margin-left: 10px;
        .tags {
            margin: 1rem 0;
            .tag {
                background-color: #000;
                margin: 0.8rem 0;
                padding: 0.3rem 0.6rem;
                border-radius: 14px;
                font-weight: 600;
                margin-right: 8px;
                color: #fff;
                img {
                    width: 0.8rem;
                    margin-left: 4px;
                }
            }
        }
        .filter-section {
            .filter {
                position: absolute;
                display: flex;
                flex-direction: row;
                background-color: #fff;
                border: 1px solid #707070;
                border-radius: 3px;
                .columns {
                    display: flex;
                    flex-direction: column;
                    margin: 0.5rem 0.4rem;
                    justify-content: center;
                    .heading {
                        font-weight: 600;
                    }
                    .data {
                        background-color: #707070;
                        border-radius: 9px;
                        padding: 3px;
                        width: 95px;
                        color: #fff;
                        justify-content: center;
                        cursor: pointer;
                    }
                    .data:last-child() {
                        margin-bottom: 0;
                    }
                }
                .vertical-seperator {
                    height: 10rem;
                    width: 1px;
                    margin: 0.3rem 0.3rem;
                    background-color: #aaa;
                }
            }
        }
    }
    .search-btn {
        background-color: #707070;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        border: none;
        color: #fff;
    }
    .pending-transaction {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .transaction-visual {
        display: flex;
        flex-direction: row;
        justify-content: start;
        .pending-section {
            text-align: center;
            .transaction-info {
                .round-btn {
                    width: 0.8rem;
                    height: 0.8rem;
                    border-radius: 50%;
                    background-color: #000;
                    margin-right: 0.3rem;
                    display: inline !important;
                }
                span {
                    font-weight: 600;
                }
            }
        }
        .square-data {
            position: relative;
            min-width: 7.5rem;
            min-height: 7.5rem;
            margin: 1rem;
            background-color: #000;
            color: #fff;
            border-radius: 1rem;
            text-align: center;
            cursor: pointer;
            padding: 0 9px;
        }
        .selected-square {
            box-shadow: 0px 0px 0px 6px rgba(56, 56, 56, 0.3);
        }
        .close-icx {
            width: 1rem;
            position: absolute;
            float: right;
            top: 5px;
            right: 5px;
        }
        .transaction-num {
            font-size: 2.5rem;
            font-weight: 600;
            padding-top: 0.7rem !important;
        }
        // .transaction-small-num {
        //   padding-top: 0.6rem !important;
        //   font-size: 2.7rem;
        //   font-weight: 600;
        // }
        .meta-info {
            margin: auto;
            font-size: 0.8rem;
        }
        .vertical-seperator {
            height: 7.5rem;
            width: 0.2rem;
            background-color: gray;
            margin: 0.8rem 1.5rem 0 1.5rem;
        }
    }
    .data {
        display: flex;
        flex-direction: row;
        justify-content: start;
        width: 70%;
        div {
            margin-right: 7rem;
        }
        .btn1 {
            margin-left: 1.4rem;
            margin-right: 100px;
        }
        .btn2 {
            margin-right: 140px;
        }
        .round-btn {
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
            background-color: #000;
            margin-right: 0.3rem;
            display: inline !important;
        }
    }
    .table-head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 1rem;
        background-color: rgba(146, 150, 125, 0.5);
        border-radius: 7px;
        padding: 0.7rem;
        .table-heading {
            font-size: 0.8rem;
            font-weight: 600;
        }
    }
    .table-body {
        // background-color: #fff;
        // margin: 1rem;
        border-radius: 7px;
        padding: 10px 0;
        hr {
            margin: 0%;
        }
    }
    .custom-table-head.extra,
    .custom-table-body.extra {
        grid-template-columns: 10% 20% 20% 20% 20% 10%;
    }
    .custom-table-head>div {
        font-weight: 700;
    }
    .round {
        background-color: #000;
        width: 16px;
        height: 16px;
        display: inline-block;
        border-radius: 50%;
        position: relative;
        top: 3px;
        left: 10px;
    }
    .table-row-section {
        padding: 10px;
        cursor: pointer;
    }
    .table-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        padding-left: 0.6rem;
        padding: 1rem;
        cursor: pointer;
        .round {
            display: inline;
            position: absolute;
            width: 17px;
            height: 17px;
            background-color: #000;
            border-radius: 50%;
            margin-left: 0.5rem;
        }
    }
    .bottom {
        display: flex;
        float: right;
        flex-direction: row;
        button {
            background-color: #fff;
            color: #707070;
            font-weight: 600;
            border: 2px solid #707070;
            border-radius: 20px;
            padding: 2px 25px 0px 25px;
            height: 28px;
            margin: 0.7rem 0.5rem;
        }
        .pages {
            display: flex;
            flex-direction: row;
            border: 1px solid #aaa;
            border-radius: 5px;
            padding: 0 10px;
            height: 30px;
            margin-top: 10px;
            img {
                height: 12px;
            }
        }
        .seperator {
            height: 1.81rem;
            width: 1px;
            background-color: #707070;
            margin: 0 10px;
        }
        span {
            margin: 0.4rem 0rem;
            margin-bottom: 0.8rem;
            cursor: pointer;
            font-weight: 600;
        }
    }
    th {
        text-align: center;
        background-color: #e4e4e480;
        font-weight: 900;
    }
    tbody {
        text-align: center;
        background-color: #fff;
        td {
            text-align: center;
        }
    }
    .table-pagination {
        .btns {
            height: 30px;
            background-color: #fff;
            color: #707070;
            font-weight: 600;
            border: 2px solid #707070;
            border-radius: 20px;
        }
        width: 98%;
        flex-direction: row;
        text-align: right;
    }
    .finance {
        overflow-y: auto;
        min-height: 80vh;
        .finance-form {
            margin-left: 1rem;
        }
        .details-btn {
            background-color: #707070;
            color: white;
            border: none;
            border-radius: 15px;
            padding: 5px 20px;
            margin-left: 1rem;
        }
        .cards {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 70%;
            .vertical-seperator {
                margin-top: 2rem;
                height: 11rem;
                width: 1px;
                background-color: #aaa;
            }
            .card-left,
            .card-right {
                margin: 1rem 1rem;
                background-color: #fff;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                width: 100%;
                .card-data {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 0 1rem;
                    .bold {
                        font-weight: 600;
                    }
                }
                .final {
                    background-color: #aaa;
                    border-radius: 3px;
                    margin: 12px;
                    padding: 6px;
                }
            }
            .card-left {
                .left {
                    margin-top: 1rem;
                }
                div {
                    margin: 3px 0;
                }
            }
            .card-right {
                div {
                    margin: 2px 0;
                }
            }
        }
        .display {
            .bottom {
                display: flex;
                float: right;
                width: 43%;
                .btns {
                    background-color: #fff;
                    color: #707070;
                    font-weight: 600;
                    border: 2px solid #707070;
                    border-radius: 20px;
                    padding: 0px 25px;
                    height: 25px;
                    margin: 1rem;
                    margin-top: 0;
                    float: right;
                }
            }
        }
    }
    .billdesk {
        .transactions {
            display: flex;
            flex-direction: row;
            justify-content: start;
            width: 53%;
            margin-left: 0rem;
            text-align: center;
            .transaction-1,
            .transaction-2 {
                margin-left: 3.2rem;
            }
            div {
                font-weight: 600;
                font-size: 0.9rem;
            }
        }
    }
}