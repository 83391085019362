/*
Template Name: Skote - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 1.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

@import '../../../node_modules/react-date-range/dist/styles.css';
@import '../../../node_modules/react-date-range/dist/theme/default.css';
@import '../../../node_modules/react-web-tabs/dist/react-web-tabs.css';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
//Fonts
@import 'custom/fonts/fonts';
//Core files
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import './variables';
@import '../../../node_modules/bootstrap/scss/mixins.scss';
// Structure
@import 'custom/structure/general';
@import 'custom/structure/topbar';
@import 'custom/structure/page-head';
@import 'custom/structure/footer';
@import 'custom/structure/right-sidebar';
@import 'custom/structure/vertical';
@import 'custom/structure/horizontal-nav';
@import 'custom/structure/layouts';
// Components
@import 'custom/components/waves';
@import 'custom/components/avatar';
@import 'custom/components/helper';
@import 'custom/components/preloader';
@import 'custom/components/forms';
@import 'custom/components/widgets';
@import 'custom/components/demos';
@import 'custom/components/print';
@import 'custom/components/inputbox';
@import 'custom/components/modal';
@import 'custom/components/transactionDetails';
// Plugins
@import 'custom/plugins/custom-scrollbar';
@import 'custom/plugins/calendar';
@import 'custom/plugins/dragula';
@import 'custom/plugins/range-slider';
@import 'custom/plugins/sweatalert2';
@import 'custom/plugins/rating';
@import 'custom/plugins/parsley';
@import 'custom/plugins/select2';
@import 'custom/plugins/switch';
@import 'custom/plugins/datepicker';
@import 'custom/plugins/form-upload';
@import 'custom/plugins/apexcharts';
$font-size: 16px;
$input-height: 2.5em; // at 16px, this an even 40px
@import '~react-widgets/lib/scss/react-widgets';
// Pages
@import 'custom/pages/authentication';
@import 'custom/pages/projects';
@import 'custom/pages/timeline';
@import 'custom/pages/vendors';
@import 'custom/pages/category';
@import 'custom/pages/product';
@import 'custom/pages/batch';
@import 'custom/pages/overview';
@import 'custom/pages/firmware';
@import 'custom/pages/history';
@import 'custom/pages/customers';
@import 'custom/pages/group';
@import 'custom/pages/subscription';
@import 'custom/pages/scrubber';
@import 'custom/pages/manageAcces';
@import 'custom/pages/lnReport';
