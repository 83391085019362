.firmware-page {
    .actions-section{
        width: 99%;
        margin:0px auto;
        padding: 15px;
        background-color: #fff;
        border: 1px solid #eee;
    }

    .actions-grid{
        width: 85%;
        display: grid;
        grid-template-columns: 20% 20% 20% 15% 10%;
        // justify-content: space-between;
        grid-column-gap: 2%;
        text-align: center;
    }

    .page-content-with-tabs {
        min-height: 50vh;
        margin-top: 10px;
        // padding: calc(#{$header-height} + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) $footer-height calc(#{$grid-gutter-width} / 2);
    }

    .search-button {
        width: 100%;
        padding: 0;
        background-color: #fff;
        color: #696969;
        font-size: 13px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: text;
    }
    .search-button>i {
        margin-right: 3px;
    }
    .firmware-details-section {
        width: 68%;
        margin: 1% 0 0 10px;
        padding: 1%;
        background-color: #fff;
        border: 1px solid #eee;
        border-bottom: 0px;
    }
    .firmware-details-section>p {
        position: relative;
        bottom: 5px;
        margin-bottom: 0px;
        padding: 0 4px;
        color: #312e2e;
        font-size: 15px;
        font-weight: 550;
    }
    .detail-box {
        padding: 5px 10px;
    }
    .detail-box>span {
        width: 25%;
        display: inline-block;
    }
    .history-section {
        width: 50%;
        margin: 0 1%;
    }
    .history-section>p {
        font-size: 14px;
        font-weight: 600;
        color: #321e2e;
    }
    .build-control-section {
        width: 68%;
        margin: 0 0 0 10px;
        padding: 1%;
        display: grid;
        grid-template-columns: 32% 32% 32%;
        background-color: #fff;
        justify-content: space-around;
        border: 1px solid #eee;
    }
    .build-control-section>div>p {
        font-size: 14px;
        font-weight: 600;
        color: #321e2e;
    }
    .build-control-section:last-child {
        border-top: 0px;
    }
    // .build-upload-section {
    //     width: 68%;
    //     margin: 0 0 0 10px;
    //     padding: 1%;
    //     display: grid;
    //     grid-template-columns: 32% 32% 32%;
    //     background-color: #fff;
    //     justify-content: space-around;
    //     border: 1px solid #eee;
    // }
    // .build-upload-section>div>p {
    //     font-size: 14px;
    //     font-weight: 600;
    //     color: #321e2e;
    // }
    .digit-selection {
        width: 100%;
        // height: 100%;
        display: grid;
        grid-template-columns: 40% 60%;
    }
    .digit-selection>input {
        height: calc(1.5em + 0.94rem + 2px);
        padding: 0 4px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    .btn-section {
        margin: 1%;
        width: 100%;
        display: flex;
        align-items: center;
    }
    .btn-section>button {
        width: 15%;
        margin-right: 1%;
    }
    .btn-section>span {
        color: #000;
    }

    .firmware-detail-block{
        margin: 15px auto;
    }

    .current-firmware-block{
        background-color: #fff;
        margin: 5px auto;
        padding : 15px
    }

    .timeline-div{
        margin: 5px;
        padding: 15px;
        width: 70%;
    }

    .firmware-details-grid{
        display: grid;
        grid-template-columns: 35% 35%;
        margin-top: 10px;
    }
    .firmware-description{
        font-size: 16px;
        margin-left: 10%;
    }
    .level-one-grid{
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
    }
    .button-panel-grid{
        display: grid;
        grid-template-columns: 10% 10% 10% ;
    }
    

    .text-label-head{
        font-size: 14px;
        margin: 4px;
    }
    .detailed-text-value{
        font-size: 19px;
        font-weight: bold;
        padding-right: 10%;
        padding-left: 5%;
    }
    .detailed-file-upload{
        padding-right: 10%;
        padding-left: 5%;
    }

    .custom-popper-in-text-margin{
        margin: 10px 6px 10px 6px;
    }

    .fit-btn{
        width: 95% !important;
        margin: 2px !important;
    }



    // ==== Modal Styles ====
    .modal-padding{
        padding: 10px 20px;
    }

    .edit-modal-grid{
        display: grid;
        grid-template-columns: 35% 60%;
        column-gap: 3%;
    }

}