.CustomCard {
    small {
        font-size: 11px;
    }
    background-color: #f8f8fb;
    .processedIcon {
        height: 1.5rem !important;
    }
    .btn-grey {
        background-color: #707070;
        border-radius: 2.3rem;
        border: none;
        color: #ffff;
        font-weight: 600;
        padding: 0 20px;
        height: 35px;
        margin-top: 0.7rem;
    }
    .card-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .card-head {
        img {
            width: 18px;
        }
        display: flex;
        padding: 20px 10px;
        flex-direction: row;
        align-items: center;
        background-color: #fff;
        border-bottom: 2px solid #eee;
        .main-heading {
            font-size: 1rem;
            margin: 0 0 0 0.5rem;
            font-weight: 600;
        }
    }
    .card-content-left {
        width: 100%;
        border-radius: 20px;
        .tab {
            display: flex;
            flex-direction: column;
            position: relative;
            background: #ffff;
            word-break: break-all;
            .tabs-container {
                display: flex;
                width: 100%;
                .tabs {
                    padding: 12px;
                    text-align: center;
                    width: 100%;
                    cursor: pointer;
                    border-bottom: 2px solid #eee;
                    box-sizing: content-box;
                    position: relative;
                    outline: none;
                }
                .active-tab {
                    border-bottom: 4px solid #000;
                }
            }
            .content-tabs {
                display: flex;
                flex-direction: column;
                .content {
                    background: white;
                    display: none;
                    padding: 0%;
                }
                .order-status {
                    display: flex;
                    flex-direction: row;
                    // margin-left: 1rem;
                    .flex-i {
                        flex: 1;
                        flex-direction: row;
                    }
                    .last-flex-i {
                        padding-right: 5%;
                        justify-content: space-between;
                        align-items: flex-start;
                    }
                    p {
                        display: flex;
                        flex-direction: row;
                        margin: 0.9rem 0;
                        justify-content: space-around;
                        align-items: center;
                        font-size: 0.8rem;
                        font-weight: 600;
                    }
                    .horizontal-seperator {
                        // width: 4rem;
                        height: 2px;
                        flex: 0.8;
                        background-color: #707070;
                        // margin-top: 1.5rem;
                    }
                    .drop-menu {
                        display: flex;
                        flex-direction: row;
                        // position: absolute;
                        // right: 15px;
                        // top: 55px;
                        border-radius: 15px;
                        padding: 3px 6px;
                        font-size: 1rem;
                    }
                    .drop-down {
                        border-radius: 15px;
                        font-size: 1rem;
                        padding: 10px;
                    }
                }
                .order-status-content {
                    width: 95%;
                    margin-left: 1rem;
                    display: flex;
                    flex-direction: column;
                    .state-error-table {
                        display: flex;
                        flex-direction: row;
                        .column {
                            // width: 25%;
                            // float: left;
                            flex: 1;
                            flex-direction: column;
                            .elem {
                                display: flex;
                                flex-direction: row;
                                height: 30px;
                                p {
                                    font-size: 12px;
                                    margin-bottom: 0px;
                                    word-break: break-word;
                                    margin-top: 4px;
                                }
                                .col {
                                    margin-left: 0rem;
                                }
                                div {
                                    margin-left: 3px;
                                }
                            }
                            .vertical {
                                width: 2px;
                                height: 33px;
                                background-color: #000;
                                margin-left: 13px;
                                margin-bottom: 8px;
                            }
                            img {
                                height: 1.7rem;
                                margin-right: 2px;
                            }
                        }
                    }
                }
                .order-status-bottom {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    p {
                        margin: 0rem 0 1rem 1rem;
                        font-weight: 600;
                    }
                    button {
                        margin: 0 0.8rem 1rem 0;
                    }
                }
                .history-top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 0.9rem;
                    p {
                        font-size: 1rem;
                        font-weight: 600;
                        margin-left: 1rem;
                    }
                    button {
                        background-color: #fff;
                        color: #707070;
                        font-weight: 600;
                        border: 2px solid #707070;
                        border-radius: 20px;
                        padding: 0px 25px;
                        height: 25px;
                        margin-right: 1rem;
                    }
                }
                .history-content {
                    img {
                        height: 20px;
                    }
                    .history-bottom {
                        button {
                            display: flex;
                            float: right;
                            padding-top: 8px;
                            margin-top: 0;
                        }
                    }
                }
                .payment-head {
                    p {
                        color: #000;
                        font-weight: 600;
                        font-size: 1rem;
                        margin: 0.6rem 0 0.6rem 1rem;
                    }
                }
                .payment-content {
                    .top {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        background-color: rgba(146, 150, 125, 0.5);
                        p {
                            padding: 0 8px;
                        }
                    }
                    .tab {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                    .card {
                        width: 95%;
                        height: 40vh;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        background-color: #fff;
                        border-radius: 15px;
                        border: 3px solid #707070;
                        margin-left: 1rem;
                        .card-body {
                            display: flex;
                            flex-direction: column;
                        }
                        .vertical-seperator {
                            height: 34vh;
                            background-color: #707070;
                            width: 1px;
                        }
                        .horizontal-seperator {
                            width: 100px;
                            margin: 0;
                            padding: 0;
                            background-color: #707070;
                        }
                    }
                    button {
                        display: flex;
                        float: right;
                        padding-top: 8px;
                        margin-top: 0;
                    }
                }
                .active-content {
                    display: block;
                    margin: 0;
                }
            }
        }
    }
    .card-content-right {
        height: 70vh;
        width: 20vw;
        margin-top: 0.5rem;
        border-radius: 10px;
        background-color: #707070;
        color: #fff;
        .main-heading {
            font-size: 0.8rem;
            font-weight: 600;
            margin: 0.7rem;
        }
        .card-body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 4px;
            .card-body-left.card-body-right {
                display: flex;
                flex-direction: column;
                .column {
                    width: 30%;
                    float: left;
                }
            }
            .card-inner-data {
                padding: 4px;
                p {
                    font-weight: 600;
                    word-break: break-all;
                }
            }
            p {
                margin-bottom: 0% !important;
            }
        }
        .card-seperator {
            height: 1px;
            width: 90%;
            background-color: #f8f8fb;
        }
        .device-details-section {
            padding: 0 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 90%;
        }
    }
    .customer-details-section {
        padding: 15px 10px;
        border-bottom: 1px solid #eee;
        h4 {
            color: #000;
            font-weight: 700;
            font-size: 16px;
            padding-bottom: 15px;
            // border-bottom: 1px solid #eee;
        }
        p {
            font-size: 14px;
            font-weight: 500;
            display: inline-block;
            width: 50%;
            color: #000;
            span {
                font-weight: 600;
            }
        }
    }
}