.group-page{
    .actions-section{
        width: 99%;
        margin:0px auto;
        padding: 15px;
        background-color: #fff;
        border: 1px solid #eee;
    }
    
    .actions-grid{
        width: 85%;
        display: grid;
        grid-template-columns: 25% 4% 15% 0% 15%;
        // justify-content: space-between;
        grid-column-gap: 2%;
        text-align: center;
    }
    
    .page-content-with-tabs {
        min-height: 50vh;
        margin-top: 10px;
        // padding: calc(#{$header-height} + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) $footer-height calc(#{$grid-gutter-width} / 2);
    }

    .search-section {
        width: 99%;
        margin: 0 auto;
        padding: 15px 10px;
        background-color: #fff;
    }
    .search-section>p {
        margin: 8px 0 0 0;
    }
    .input-grid-section {
        display: grid;
        grid-template-columns: 12% 25% 15% 10%;
        grid-column-gap: 10px;
        margin-top: 6px;
        // justify-content: space-between;
    }
    
    .search-button{
        width: 100%;
        padding: 0; 
        background-color: #fff;
        color: #696969;
        font-size: 13px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: text;
    }
    
    .search-button > i{
        margin-right: 3px;
    }
    
    .or-text{
        margin-bottom: 0;
        color: #212020;
        font-size: 14px;
        justify-self: center;
        align-self: center;
    }
    
    .search-grid{
        width: 100%;
        padding: 10px 0 0 0;
        display: grid;
        grid-template-columns: 45% 3% 10% 3%;
        grid-column-gap: 15px;
    }
    
    .search-input-box{
        padding: 5px;
        display: grid;
        grid-template-columns: 5% 95%;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    
    .search-input-box > i{
        font-size: 18px;
        justify-self: center;
        align-self: center;
    }
    
    .search-input-box > input{
        padding-left: 3px;
        color: #545057;
        border: none;
        outline: none;
    }


    .current-group-block{
        background-color: #fff;
        margin: 5px auto;
        padding : 15px
    }

    .level-one-grid{
        display:  grid;
        grid-template-columns: 35% 35%;
        margin-top: 10px;
    }

    .level-two-grid{
        display: grid;
        grid-template-columns: 45% 45%;
        margin-bottom: 8px;
    }

    .group-selection{
        display: grid;
        grid-template-columns: 30% 20%;
        margin-top: 15px;
    }

    .user-description{
        font-size: 16px;
        margin-left: 10%;
    }
}