.subscription-page {
    .detailing{
        font-size: 30px;
        font-weight: bold;
    }

    .custom-tab-button>button {
        padding: 1rem 1rem !important;
        font-size: 16px !important;
    }

    .custom-tab-button>button::after{
        border-bottom: 3px solid #000 !important;
    }

    .individual-block{
        background-color: #fff;
        margin: 5px auto;
        padding : 15px
    }

    .sectional-grid-level-1 {
        display: grid;
        grid-template-columns: 47% 47%;
        grid-column-gap: 2%;
    }

    .sectional-grid-level-2 {
        display: grid;
        grid-template-columns: 30% 30% 30%;
        grid-column-gap: 2%;
    }

    .justify-end {
        justify-self: end;
    }

    .add-btn-holder{
        width: 52%;
        height: auto;
    }
    .add-btn-grid{
        display: grid;
        grid-template-columns: 49% 49%;
        column-gap: 1%;
    }

    .card-grid-level-1 {
        display: grid;
        grid-template-columns: 15% 15% 15% 15% 15%;
        margin-bottom: 15px;
        grid-column-gap: 2% ;
    }

    .card-layout-plan {
        background-color: #fff;
        height: 200px;
    }

    .card-header {        
        background-color: #d8d8d8;
        height : 60%;
        text-align: center;
        font-size: 17px;
        font-weight: bold;
    }

    .card-footer {
        background-color: #fff;
        height: 40%;     
        text-align: center;   
    }

    .subscription-plan-list{
        font-size: 16px;
        margin-bottom: 10px;
    }

    .subscription-plan-name{
        word-break: break-all;
    }

    .chip-grid-level-1 {
        display: grid;
        grid-template-columns: 12% 12% 12% 12% 12% 12%;
        grid-column-gap: 1% ;
    }

    .button-grid-level-1 {
        display: grid;
        grid-template-columns: 18% 18% 18%;
        grid-column-gap: 3%;
    }

    .promotion-tag {
        margin: 5px;
        padding: 10px 12px;
        font-weight: 700;
        border-radius: 20px;
        text-align: center;
        font-size: 16px;
    }

    .active-tag {
        background-color: #fff;
        border-style: solid;
        border-color: #000;
    }

    .inactive-tag {
        background-color: #efefef;
    }

    .action-validation-text {
        color: #ff0000;
        font-size: 11px;
    }

    .plan-card-container {
        width: 100%;
        height : 200px;
        margin: 2%;
        background-color: #eee;
        border: 1px solid #eee;
        border-radius: 10px;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        cursor: pointer;
    }

    .custom-group-body{
        position: relative;
        margin: 6% 0%;
        border: 1px solid #b5b5b5;
        padding: 2.3rem 1rem 1rem 1rem !important;
        border-radius: 10px;
    }
    .custom-group-header{
        position: absolute;
        left: 5%;
        top: -17px;
        font-size: 18px;
        font-weight: bold;
        background-color: #fff;
        margin: 2px 5px ;
    }

    .modal-body-frame{
        margin: 10px 15px;
    }

    .expanding-text{
        margin-top: 15px;
        font-size: 15px;
        font-weight: bold;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10+ and Edge */
        user-select: none; /* Standard syntax */  
    }

    .checkbox-input{
        width: 18px;
        height: 18px;
    }


    .ln-box{
        display: grid;
        grid-template-columns: 40% 40%;
        column-gap: 5%;
    }

    .ln-group-margin{
        margin: 1% 1% 4% 1% !important;
    }
    
}