//
// _helper.scss
//
.confirm-btn {
    background-color: #fff;
    color: #000 !important;
    border: 1px solid #3e3838;
    border-radius: 25px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    letter-spacing: 1px;
}

.confirm-btn:hover,
.confirm-btn:focus {
    color: #fff !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-22 {
    font-size: 22px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

// Font weight help class
.font-weight-medium {
    font-weight: $font-weight-medium;
}

.font-weight-semibold {
    font-weight: $font-weight-semibold;
}

// Social
.social-list-item {
    height: 2rem;
    width: 2rem;
    line-height: calc(2rem - 2px);
    display: block;
    border: 1px solid $gray-500;
    border-radius: 50%;
    color: $gray-500;
    text-align: center;
}

.w-xs {
    min-width: 80px;
}

.w-sm {
    min-width: 95px;
}

.w-md {
    min-width: 110px;
}

.w-lg {
    min-width: 140px;
}

.w-xl {
    min-width: 160px;
}

// Margins
.m-t-two {
    margin-top: 2%;
}

.m-b-two {
    margin-bottom: 2%;
}

.m-b-three {
    margin-bottom: 3%;
}

.m-b-four {
    margin-bottom: 4%;
}

.m-b-five {
    margin-bottom: 5%;
}

.m-b-six {
    margin-bottom: 6%;
}

.m-b-seven {
    margin-bottom: 7%;
}

.m-b-eight {
    margin-bottom: 8%;
}
.m-b-ten {
    margin-bottom: 10%;
}

.m-l-2 {
    margin-left: 2%;
}
.m-l-5 {
    margin-left: 5%;
}

.m-l-10 {
    margin-left: 10%;
}

.m-t-2{
    margin-top: 2%;
}

.m-t-5{
    margin-top: 5%;
}
.m-t-8{
    margin-top : 8%;
}

.m-t-9{
    margin-top : 9%;
}

.m-t-10 {
    margin-top: 10%;
}

.m-t-11{
    margin-top : 11%;
}

.m-t-12 {
    margin-top: 12%;
}

.m-t-15 {
    margin-top: 15%;
}


.mt-10 {
    margin-top: 10%;
}

.mr-2 {
    margin-right: 2%;
}

.m-v-2 {
    margin: 2% 0;
}

.m-t-3 {
    margin-top: 3%;
}

.m-tp-10{
    margin-top: 10px;
}

// End of Margins
.p-color {
    color: #000;
}

.golden-color {
    color: $golden;
}

.p-10 {
    padding: 10px;
}

.p-18 {
    padding: 18px;
}

.p-l-10 {
    padding-left: 10px;
}

.height-auto {
    height: auto !important;
}

.full-screen-login {
    // background-image: url('../../../images/login-bg.jpg');
    background-image: url('../../../images/trans-back.png');
    height: 100vh;
    padding-top: 16% !important;
    background-size: cover;
    background-position: right;
}

.disp-none {
    display: none;
}

.select-error>.rw-widget-container {
    // color: #CF4343;
    border-color: #CF4343 !important;
}

.select-error {
    border-color: #CF4343 !important;
}

.color-red {
    color: #e83333c4;
}

.color-blue {
    color: #7bb0f1;
}

.color-cyan {
    color: #85bed4;
}

.color-grey {
    color: #8c8c8c
}

.color-green {
    color: #31af08;
}

.objectFit {
    object-fit: contain;
}

.colorGolden {
    color: $golden;
}

.cur-pointer {
    cursor: pointer;
}

.cur-notAllowed {
    cursor: not-allowed;
}

.font-sixteen {
    font-size: 16px !important;
}

.font-fourteen {
    font-size: 14px !important;
}

.font-twenty {
    font-size: 20px !important;
}

.font-eighteen{
    font-size: 18px !important;
}

.bg-black {
    background-color: $black;
}

.t-a-l {
    text-align: left;
}

.b-1 {
    border: 0.2px solid #b5b5b5;
}

.popper-width-inc {
    height: 300px !important;
    overflow: auto !important;
}

.force-download-color{
    color: #7bb0f1;
}
.select-hover{
    cursor: pointer;
}
.select-hover-not-allowed{
    cursor: not-allowed;
}

.text-center{
    text-align: center;
}

.word-break-all{
    word-break: break-all;
}