.vendor-page {
    .actions-section {
        width: 99%;
        margin: 0px auto;
        padding: 15px;
        background-color: #fff;
        border: 1px solid #eee;
    }
    .actions-grid {
        width: 90%;
        // margin: 10px 0 0 0;
        display: grid;
        grid-template-columns: 20% 10% 5% 1% 15%;
        grid-column-gap: 2%;
        text-align: center;
    }
    .search-button {
        width: 100%;
        padding: 0;
        background-color: #fff;
        color: #696969;
        font-size: 13px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: text;
    }
    .search-button>i {
        margin-right: 3px;
    }
    .or-text {
        margin-bottom: 0;
        color: #212020;
        font-size: 14px;
        justify-self: center;
        align-self: center;
    }
    .search-grid {
        width: 100%;
        padding: 10px 0 0 0;
        display: grid;
        grid-template-columns: 45% 3% 10% 3%;
        grid-column-gap: 15px;
    }
    .search-input-box {
        padding: 5px;
        display: grid;
        grid-template-columns: 5% 95%;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    .search-input-box>i {
        font-size: 18px;
        justify-self: center;
        align-self: center;
    }
    .search-input-box>input {
        padding-left: 3px;
        color: #545057;
        border: none;
        outline: none;
    }
    .icon-btn {
        height: 100%;
        width: 100%;
        border: 1px solid #9f9fa5;
        border-radius: 5px;
        background-color: #9f9fa5;
        cursor: pointer;
    }
    .icon-btn>i {
        margin-top: 8px;
        display: grid;
        justify-content: center;
        font-size: 18px;
        color: #fff;
    }
    .pins-section {
        margin: 10px 0px 0 0;
    }
    .pins-tag {
        margin: 0 3px;
        padding: 5px 8px;
        background-color: #eaeaea;
        border-radius: 15px;
        cursor: pointer;
    }
    .pins-tag>i {
        margin: 0 4px;
    }
    .table-section {
        width: 99%;
        margin: 1% auto;
    }
    .table-row-section {
        margin: 0 0 1% 0;
        padding: 0 10px;
        background-color: #fff;
        border-radius: 8px;
        // box-shadow: 0 2px 3px 1px #cccccc;
    }
    .table-row-section:hover {
        box-shadow: 0 2px 3px 1px #cccccc;
    }
    .name-fieldbox>i:first-of-type {
        margin: 0 10px 0 0;
    }
    .name-fieldbox>i:last-of-type {
        margin: 0 10px;
    }
    .has-edit-icon>i {
        margin-left: 6px;
    }
    .custom-table-head,
    .custom-table-body {
        display: grid;
        grid-template-columns: 25% 25% 25% 10% 10%;
        align-items: center;
    }
    .custom-table-head {
        padding: 10px;
    }
    .initial-status {
        width: 55%;
        padding: 5px 2px;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-column-gap: 1px;
        align-items: center;
        justify-items: center;
    }
    .initial-status>button {
        width: 100%;
        font-size: 14px;
        // background-color: #9f9fa5;
        // border: 1px solid #9f9fa5;
    }
    .status-change {
        display: none;
        padding: 5px 0;
    }
    .pad-r {
        padding-right: 8px;
    }
    .collapse-table-section {
        display: grid;
        grid-template-columns: 25% 40% 20% 10%;
        justify-content: left;
        padding: 15px 0;
    }
    .collapse-first-col>i {
        color: $secondary;
        font-size: 50px;
    }
    .collapse-first-col {
        display: grid;
        grid-template-columns: 15% 84%;
        align-items: center;
    }
    .collapse-third-col {
        align-self: center;
    }
    .load-btn {
        width: 35%;
        margin-bottom: 8px;
        padding: 3px 10px;
        display: block;
        background-color: $secondary;
        color: #fff;
        border: 2px solid $secondary;
        border-radius: 15px;
    }
    .collapse-fourth-col {
        font-size: 30px;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 1280px) {
        .search-grid {
            grid-template-columns: 50% 5% 10% 5%;
            grid-column-gap: 15px;
        }
        .actions-grid {
            grid-template-columns: 25% 15% 7% 1% 20%;
        }
        .initial-status {
            width: 70%;
        }
        .collapse-table-section {
            grid-template-columns: 25% 45% 15% 10%;
        }
        .collapse-first-col>i {
            font-size: 30px;
        }
        .collapse-first-col {
            font-size: 12px;
        }
        .collapse-second-col {
            font-size: 11px;
            align-self: center;
        }
        .load-btn {
            width: 90%;
            margin: 10px auto;
        }
    }
    @media (max-width: 441px) {
        .search-grid {
            grid-template-columns: 80% 15%;
            grid-column-gap: 4%;
            grid-row-gap: 8px;
        }
        .actions-section {
            padding: 10px;
        }
    }
}